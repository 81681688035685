import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Dashboard",
        meta: { color: "brown" },
        component: Dashboard
    },
    {
        path: "/login",
        name: "Login",
        meta: { color: "brown", layout: "Login", guest: true },
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Login.vue")
    },
    {
        path: "/access_denied",
        name: "Access Denied",
        meta: { color: "blue", layout: "simpleLayout", no_tab: true },
        component: () =>
            import(
                /* webpackChunkName: "access_denied" */ "../views/General/AccessDenied.vue"
            )
    },
    {
        path: "/employee/:id?",
        name: "Employee Entry",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "employee" */ "../views/Employee/Employee.vue"
            )
    },
    {
        path: "/employees",
        name: "Employee List",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "employees" */ "../views/Employee/Employees.vue"
            )
    },
    {
        path: "/ex-employees",
        name: "Ex-Employee List",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "ex-employees" */ "../views/Employee/Ex-Employees.vue"
            )
    },
    {
        path: "/employee-details/:id",
        name: "Employee Details",
        meta: { color: "lime darken-2", layout: "simpleLayout" },
        component: () =>
            import(
                /* webpackChunkName: "employee-details" */ "../views/Employee/EmployeeDetails.vue"
            )
    },
    {
        path: "/leave/:id?",
        name: "Leave Entry",
        meta: { color: "lime darken-2", access: true },
        component: () =>
            import(/* webpackChunkName: "leave" */ "../views/Leave/Leave.vue")
    },
    {
        path: "/leave_type",
        name: "Leave Type",
        meta: { color: "lime darken-2", access: true },
        component: () =>
            import(
                /* webpackChunkName: "leave_type" */ "../views/Leave/LeaveType.vue"
            )
    },
    {
        path: "/leaves",
        name: "Leave Record",
        meta: { color: "lime darken-2", access: true },
        component: () =>
            import(
                /* webpackChunkName: "leaves" */ "../views/Leave/LeaveRecord.vue"
            )
    },
    {
        path: "/leave_list",
        name: "Leave List",
        meta: { color: "lime darken-2", access: true },
        component: () =>
            import(
                /* webpackChunkName: "leave_list" */ "../views/Leave/LeaveList.vue"
            )
    },
    {
        path: "/present_list",
        name: "Present List",
        meta: { color: "lime darken-2", access: true },
        component: () =>
            import(
                /* webpackChunkName: "present_list" */ "../views/Attendance/PresentList.vue"
            )
    },
    {
        path: "/late_list",
        name: "Late List",
        meta: { color: "lime darken-2", access: true },
        component: () =>
            import(
                /* webpackChunkName: "late_list" */ "../views/Attendance/LateList.vue"
            )
    },
    {
        path: "/absent_list",
        name: "Absent List",
        meta: { color: "lime darken-2", access: true },
        component: () =>
            import(
                /* webpackChunkName: "absent_list" */ "../views/Attendance/AbsentList.vue"
            )
    },
    {
        path: "/manual-attendances",
        name: "Manual Attendance",
        meta: { color: "lime darken-2", access: true },
        component: () =>
            import(
                /* webpackChunkName: "manual-attendances" */ "../views/Attendance/ManualAttendances.vue"
            )
    },
    {
        path: "/attendance-process",
        name: "Attendance Process",
        meta: { color: "lime darken-2", access: true },
        component: () =>
            import(
                /* webpackChunkName: "attendance-process" */ "../views/Attendance/AttendanceProcess.vue"
            )
    },
    {
        path: "/department",
        name: "Department Entry",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "department" */ "../views/Employee/Departments.vue"
            )
    },
    {
        path: "/designation",
        name: "Designation Entry",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "designation" */ "../views/Employee/Designations.vue"
            )
    },
    {
        path: "/shift",
        name: "Shift Entry",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "shift" */ "../views/General/Shifts.vue"
            )
    },
    {
        path: "/month",
        name: "Month Entry",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "month" */ "../views/General/Months.vue"
            )
    },
    {
        path: "/holiday_management",
        name: "Holiday Management",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "holiday_management" */ "../views/General/HolidaysWeekend.vue"
            )
    },
    {
        path: "/shift_management",
        name: "Shift Management",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "shift_management" */ "../views/General/ShiftManagement.vue"
            )
    },
    {
        path: "/attendance_report",
        name: "Attendance Report",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "attendance_report" */ "../views/Attendance/AttendanceReport.vue"
            )
    },
    {
        path: "/attendance_summary_report",
        name: "Attendance Summary Report",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "attendance_summary_report" */ "../views/Attendance/AttendanceSummaryReport.vue"
            )
    },
    {
        path: "/attendance_log",
        name: "Attendance Log",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "attendance_log" */ "../views/Attendance/AttendanceLog.vue"
            )
    },
    {
        path: "/promotion",
        name: "Promotion Entry",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "promotion" */ "../views/Employee/Promotion.vue"
            )
    },
    {
        path: "/increment",
        name: "Increment Entry",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "increment" */ "../views/Employee/Increment.vue"
            )
    },
    {
        path: "/area",
        name: "Area Entry",
        meta: { color: "blue", access: true },
        component: () =>
            import(/* webpackChunkName: "area" */ "../views/General/Areas.vue")
    },
    {
        path: "/country",
        name: "Country Entry",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "country" */ "../views/General/Country.vue"
            )
    },
    {
        path: "/bank",
        name: "Bank Entry",
        meta: { color: "blue", access: true },
        component: () =>
            import(/* webpackChunkName: "bank" */ "../views/General/Bank.vue")
    },
    {
        path: "/loan",
        name: "Loan Entry",
        meta: { color: "blue", access: true },
        component: () =>
            import(/* webpackChunkName: "loan" */ "../views/Loan/Loan.vue")
    },
    {
        path: "/loans",
        name: "Loan List",
        meta: { color: "blue", access: true },
        component: () =>
            import(/* webpackChunkName: "loans" */ "../views/Loan/LoanList.vue")
    },
    {
        path: "/loan_statement",
        name: "Loan Statement",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "loan_statement" */ "../views/Loan/LoanStatement.vue"
            )
    },
    {
        path: "/loan_balance_report",
        name: "Loan Balance Report",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "loan_balance_report" */ "../views/Loan/LoanBalanceReport.vue"
            )
    },
    {
        path: "/loan_adjustment",
        name: "Loan Adjustment",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "loan_adjustment" */ "../views/Loan/LoanAdjustment.vue"
            )
    },
    {
        path: "/transaction/:id?",
        name: "Transaction",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "transaction" */ "../views/Salary/Transaction.vue"
            )
    },
    {
        path: "/transaction_record",
        name: "Transaction Record",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "transaction_record" */ "../views/Salary/TransactionRecord.vue"
            )
    },
    {
        path: "/pf_transaction/:id?",
        name: "PF Transaction",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "pf_transaction" */ "../views/PF/PFTransaction.vue"
            )
    },
    {
        path: "/pf_transaction_record",
        name: "PF Transaction Record",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "pf_transaction_record" */ "../views/PF/PFTransactionRecord.vue"
            )
    },
    {
        path: "/pf_balance_report",
        name: "PF Balance Report",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "pf_balance_report" */ "../views/PF/PFBalanceReport.vue"
            )
    },
    {
        path: "/pf_ledger",
        name: "PF Ledger",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "pf_ledger" */ "../views/PF/PFLedger.vue"
            )
    },
    {
        path: "/salary_generate",
        name: "Salary Generate",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "salary_generate" */ "../views/Salary/SalaryGenerate.vue"
            )
    },
    {
        path: "/salary_report",
        name: "Salary Report",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "salary_report" */ "../views/Salary/SalaryReport.vue"
            )
    },
    {
        path: "/pay_slip",
        name: "Pay Slip",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "pay_slip" */ "../views/Salary/PaySlip.vue"
            )
    },
    {
        path: "/salary_sheet/:id",
        name: "Salary Sheet",
        meta: { color: "blue", no_tab: true },
        component: () =>
            import(
                /* webpackChunkName: "salary_sheet" */ "../views/Salary/SalarySheet.vue"
            )
    },

    {
        path: "/bank_sheet",
        name: "Bank Sheet",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "salary_sheet" */ "../views/Salary/BankSheet.vue"
            )
    },
    {
        path: "/user_management",
        name: "User Management",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "user_management" */ "../views/General/UserManagement.vue"
            )
    },
    {
        path: "/user_access/:id",
        name: "User Access",
        meta: { color: "blue", no_tab: true },
        component: () =>
            import(
                /* user_access: "salary_sheet" */ "../views/General/UserAccess.vue"
            )
    },
    {
        path: "/company_profile",
        name: "Company Profile",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "company_profile" */ "../views/General/CompanyProfile.vue"
            )
    },
    {
        path: "/notice",
        name: "Notice Management",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "notice" */ "../views/General/NoticeManagement.vue"
            )
    },
    {
        path: "/bonus_generate/:id?",
        name: "Bonus Generate",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "bonus_generate" */ "../views/Bonus/BonusGenerate.vue"
            )
    },
    {
        path: "/bonus_report",
        name: "Bonus Report",
        meta: { color: "blue", access: true },
        component: () =>
            import(
                /* webpackChunkName: "bonus_report" */ "../views/Bonus/BonusReport.vue"
            )
    },
    {
        path: "/bonus_sheet/:id",
        name: "Bonus Sheet",
        meta: { color: "blue", no_tab: true },
        component: () =>
            import(
                /* webpackChunkName: "bonus_sheet" */ "../views/Bonus/BonusSheet.vue"
            )
    }
];

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes
});

// middleware
router.beforeEach((to, from, next) => {
    if (to.meta.guest) {
        if (store.getters["user/isLoggedIn"]) {
            next("/");
            return;
        }
        next();
    } else {
        if (store.getters["user/isLoggedIn"]) {
            if (
                to.meta.access &&
                store.state["user"].role != "Admin" &&
                !store.state["user"].permissions.includes(to.name)
            ) {
                next("/access_denied");
                return;
            }
            next();
            return;
        }
        next("/login");
    }
});

export default router;
