<template>
	<v-app>
		<v-navigation-drawer clipped app dark color="blue-grey darken-4" width="250">
			<v-treeview class="left-menu mt-2" dense :items="menuItems" activatable open-on-click item-key="id"
				:selectable="shortcutEditMode" selection-type="independent"
				:item-disabled="shortcutEditMode ? 'locked' : 'none'" v-model="shortcutMenus" return-object>
				<template v-slot:label="{ item }">
					<span v-if="item.children">
						<v-icon small v-if="item.icon && item.locked != undefined" :color="item.color" class="mr-1">
							{{ item.icon }}
						</v-icon>
						{{ item.name }}
					</span>
					<div v-else-if="item.click" @click="handle_function(item.method)">
						<v-icon small v-if="item.icon && item.locked != undefined" :color="item.color" class="mr-1">{{
							item.icon }}</v-icon>
						{{ item.name }}
					</div>

					<router-link :to="item.link" v-else>
						<div>
							<v-icon small v-if="item.icon && item.locked != undefined" :color="item.color"
								class="mr-1">{{ item.icon }}</v-icon>
							{{ item.name }}
						</div>
					</router-link>
				</template>
			</v-treeview>
		</v-navigation-drawer>

		<v-app-bar :elevation="1" extended clipped-left fixed app class="custom-app-bar">
			<v-container fluid class="py-0 px-1 light-blue darken-1">
				<v-row dense>
					<template v-for="(menu, ind) in menuItems">
						<v-col v-if="menu.children" cols="auto" class="pa-0" :key="ind">
							<v-menu offset-y :close-on-content-click="false">
								<template v-slot:activator="{ on }">
									<v-btn text dark tile small v-on="on" class="px-1 caption">
										{{ menu.name }}
									</v-btn>
								</template>
								<v-treeview style="width: 250px; overflow: hidden" class="left-menu white" dense
									:items="menu.children" activatable item-key="name" open-on-click>
									<template v-slot:label="{ item }">
										<span v-if="item.children"><v-icon small
												v-if="item.icon && item.locked != undefined" :color="item.color"
												class="mr-1">{{ item.icon }}</v-icon>
											{{ item.name }}</span>
										<router-link :to="item.link" v-else>
											<div>
												<v-icon small v-if="item.icon && item.locked != undefined"
													:color="item.color" class="mr-1">{{ item.icon }}</v-icon>
												{{ item.name }}
											</div>
										</router-link>
									</template>
								</v-treeview>
							</v-menu>
						</v-col>
						<v-col v-else-if="menu.click" cols="auto" class="pa-0" :key="ind">
							<v-menu offset-y :close-on-content-click="false">
								<template v-slot:activator="{ on }">
									<v-btn @click="handle_function(menu.method)" text dark tile small v-on="on"
										class="px-1 caption white--text">
										{{ menu.name }}
									</v-btn>
								</template>
							</v-menu>
						</v-col>
						<v-col v-else cols="auto" class="pa-0" :key="ind">
							<v-menu offset-y :close-on-content-click="false">
								<template v-slot:activator="{ on }">
									<v-btn :to="menu.link" text dark tile small v-on="on"
										class="px-1 caption white--text">
										{{ menu.name }}
									</v-btn>
								</template>
							</v-menu>
						</v-col>

						<v-divider vertical inset class="light-blue lighten-3 my-1" :key="`dvd${ind}`"></v-divider>
					</template>

					<v-spacer></v-spacer>

					<v-col cols="auto" class="pa-0 light-blue darken-3">
						<v-menu offset-y>
							<template v-slot:activator="{ on }">
								<v-btn text dark tile small v-on="on" class="px-1">
									<v-icon small class="mr-1">mdi-account-circle</v-icon>
									<span class="caption">{{ $store.getters['user/getUser'].name }}</span>
								</v-btn>
							</template>

							<v-list nav dense class="top-menu">
								<v-list-item>
									<v-list-item-icon class="mr-1 my-0">
										<v-icon small>mdi-account-edit</v-icon>
									</v-list-item-icon>

									<v-list-item-content class="py-0">
										<v-list-item-title>Profile Settings</v-list-item-title>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-icon class="mr-1 my-0">
										<v-icon small>mdi-key-change</v-icon>
									</v-list-item-icon>

									<v-list-item-content class="py-0">
										<v-list-item-title>Change Password</v-list-item-title>
									</v-list-item-content>
								</v-list-item>

								<v-list-item @click="logout">
									<v-list-item-icon class="mr-1 my-0">
										<v-icon small>mdi-logout</v-icon>
									</v-list-item-icon>

									<v-list-item-content class="py-0">
										<v-list-item-title>Logout</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
				</v-row>
			</v-container>

			<template v-slot:extension>
				<v-container fluid class="pa-0 grey lighten-5">
					<v-row dense class="pl-1 grey darken-3">
						<v-col cols="auto" class="pa-1 pr-0" v-for="(item, ind) in shortcutMenus" :key="ind"
							style="position:relative">
							<div v-if="shortcutEditMode" @click="shortcutRemove(ind)" class="shortcut-close">
								<v-icon>mdi-close</v-icon>
							</div>

							<v-card :elevation="1" :to="item.link" min-width="80px">
								<v-card-text align="center" class="pa-0 px-1 pt-1">
									<v-icon :color="item.color">{{
										item.icon
									}}</v-icon><br />
									<strong style="font-size: 12px">{{
										item.name
									}}</strong>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col cols="auto" class="pa-1 pr-0">
							<v-card @click="changeShortcutMenus" width="78" height="50" color="grey lighten-2"
								:elevation="1">
								<v-card-text align="center" class="pa-0 pt-3">
									<v-icon color="black"
										v-if="shortcutEditMode">mdi-checkbox-marked-circle-outline</v-icon>
									<v-icon color="black" v-else>mdi-plus-circle-outline</v-icon>
								</v-card-text>
							</v-card>
						</v-col>

						<v-spacer></v-spacer>

						<v-col cols="auto" class="pa-0">
							<div id="clock" class="grey darken-3">
								<p class="date">
									{{
										time.toLocaleString("en-US", {
											day: "numeric",
											month: "numeric",
											year: "numeric",
											weekday: "short",
										})
									}}
								</p>
								<p class="time">
									{{
										time.toLocaleString("en-US", {
											hour: "numeric",
											minute: "numeric",
											second: "numeric",
											hour12: true,
										})
									}}
								</p>
							</div>
						</v-col>
					</v-row>

					<v-divider></v-divider>

					<v-row no-gutters>
						<v-col cols="auto" style="width: 250px">
							<v-card dark flat tile color="light-blue darken-3 d-flex align-center justify-center"
								height="32">
								<span class="subtitle-2">Link-Up Technology Ltd.</span>
							</v-card>
						</v-col>

						<v-col cols="auto" style="width: calc(100% - 250px)">
							<v-card tile dark color="grey lighten-4" :elevation="1" height="30px">
								<v-card-text class="pa-0">
									<v-tabs class="open-pages" active-class="active-tab" light
										background-color="grey lighten-4" show-arrows height="32px">
										<v-tabs-slider color="grey lighten-4"></v-tabs-slider>

										<v-tab class="pa-0" v-for="(page, ind) in openPages" :key="ind">
											<v-chip :close="page.name == 'Dashboard'
													? false
													: true
												" label dark :color="page.active
														? page.color
														: 'grey lighten-2 black--text'
													" class="my-1 ml-1" small :to="page.link" @click:close="closeTab(ind)">
												{{ page.name }}
											</v-chip>
										</v-tab>
									</v-tabs>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</v-app-bar>

		<v-main>
			<v-container fluid class="pa-0 mt-1">
				<v-row>
					<v-col class="py-0">
						<router-view />
					</v-col>
				</v-row>
			</v-container>
		</v-main>

		<v-footer app dark color="#424242" class="pa-0" height="26px">
			<v-container fluid class="pa-0">
				<v-row>
					<v-col cols="10" class="py-0">
						<marquee direction="left" scrollamount="2" onmouseover="this.stop();"
							onmouseout="this.start();">
							<span class="caption">{{ linkup_api_content }}</span>
						</marquee>
					</v-col>
					<v-col cols="2" class="py-0 pr-0"
						style="padding: 4px 0;background-color: #3e2e6b;color:white; margin-bottom: -1px;text-align: center;">
						<span style="font-size: 12px;">
							Developed by <a href="http://linktechbd.com/" target="_blank"
								style="color: white;text-decoration: underline;font-weight: normal;">Link-Up
								Technology</a>
						</span>
					</v-col>
				</v-row>
			</v-container>
		</v-footer>

		<snackbar></snackbar>
		<titleComponent
			:title="'HR & Payroll Software | ' + $store.getters['company/company'].name + ' | Link-Up Technology Ltd.'">
		</titleComponent>
	</v-app>
</template>

<script>
import snackbar from '../components/snackbar.component';
import titleComponent from '../components/title.component';
import utility from '../mixins/utility.mixin';
export default {
	mixins: [utility],
	components: {
		snackbar,
		titleComponent,
	},

	data() {
		return {
			time: "",
			linkup_api_content: '',
			shortcutEditMode: false,
			shortcutMenus: [],
			openPages: [
				{
					name: "Dashboard",
					link: "/",
					active: false,
					color: "brown"
				}
			],
			menuItems: [
				{
					name: "Dashboard",
					icon: "mdi-apps",
					link: "/",
					color: "brown",
					show: false,
					locked: true,
					id: "difw9l",
				},
				{
					name: "Employee Module",
					icon: "mdi-account-group",
					color: "blue",
					show: false,
					locked: true,
					children: [
						{
							name: "Employee Entry",
							link: "/employee",
							color: "blue",
							id: "5zak3n",
							icon: "mdi-account-clock",
						},
						{
							name: "Employee List",
							link: "/employees",
							color: "blue",
							id: "vqyzr",
							icon: "mdi-account-clock",
						},
						{
							name: "Ex-Employee List",
							link: "/ex-employees",
							color: "blue",
							id: "vqyzry",
							icon: "mdi-account-clock",
						},
					],
					id: "qowpep",
				},
				{
					name: "Attendance Module",
					icon: "mdi-fingerprint",
					color: "orange",
					show: false,
					locked: true,
					children: [
						{
							name: "Manual Attendance",
							link: "/manual-attendances",
							color: "orange",
							id: "o6uiuae",
							icon: "mdi-account-clock",
						},
						{
							name: "Attendance Process",
							link: "/attendance-process",
							color: "orange",
							id: "bxl6bc",
							icon: "mdi-account-clock",
						},
						{
							name: "Reports",
							icon: "mdi-file-document",
							link: "/",
							color: "orange",
							locked: true,
							children: [
								{
									name: "Attendance Report",
									link: "/attendance_report",
									color: "orange",
									id: "kahvt5",
									icon: "mdi-cart",
								},
								{
									name: "Attendance Summary Report",
									link: "/attendance_summary_report",
									color: "orange",
									id: "kalvtl5",
									icon: "mdi-cart",
								},
								{
									name: "Attendance Log",
									link: "/attendance_log",
									color: "orange",
									id: "kalrtl5",
									icon: "mdi-cart",
								},
								{
									name: "Present List",
									link: "/present_list",
									color: "orange",
									id: "kawlvhn5",
									icon: "mdi-cart",
								},
								{
									name: "Late List",
									link: "/late_list",
									color: "orange",
									id: "kawlvt5",
									icon: "mdi-cart",
								},
								{
									name: "Leave List",
									link: "/leave_list",
									color: "orange",
									id: "kahlvt5",
									icon: "mdi-cart",
								},
								{
									name: "Absent List",
									link: "/absent_list",
									color: "orange",
									id: "kawlvn5",
									icon: "mdi-cart",
								},
							],
							id: "95yvfon",
						},
					],
					id: "oh8w8s",
				},
				{
					name: "Leave Module",
					icon: "mdi-hiking",
					link: "/",
					color: "teal",
					show: false,
					locked: true,
					children: [
						{
							name: "Leave Entry",
							link: "/leave",
							color: "teal",
							id: "q37ap",
							icon: "mdi-account-clock",
						},
						{
							name: "Leave Record",
							link: "/leaves",
							color: "teal",
							id: "q3c7ap",
							icon: "mdi-account-clock",
						},
						{
							name: "Leave List",
							link: "/leave_list",
							color: "teal",
							id: "q8c7ap",
							icon: "mdi-account-clock",
						},
						{
							name: "Leave Type",
							link: "/leave_type",
							color: "teal",
							id: "q37af",
							icon: "mdi-account-clock",
						},
					],
					id: "1vtccp",
				},
				{
					name: "Loan Module",
					icon: "mdi-currency-bdt",
					link: "/",
					color: "lime",
					show: false,
					locked: true,
					children: [
						{
							name: "Loan Entry",
							link: "/loan",
							color: "lime",
							id: "q37axp",
							icon: "mdi-account-clock",
						},
						{
							name: "Loan Adjustment",
							link: "/loan_adjustment",
							color: "lime",
							id: "q37zap",
							icon: "mdi-account-clock",
						},
						{
							name: "Reports",
							icon: "mdi-file-document",
							link: "/",
							color: "lime",
							locked: true,
							children: [
								{
									name: "Loan List",
									link: "/loans",
									color: "lime",
									id: "k4eroq",
									icon: "mdi-cart",
								},
								{
									name: "Loan Statement",
									link: "/loan_statement",
									color: "lime",
									id: "k4rcoq",
									icon: "mdi-cart",
								},
								{
									name: "Loan Balance Report",
									link: "/loan_balance_report",
									color: "lime",
									id: "k4rcxq",
									icon: "mdi-cart",
								},

							],
							id: "95yvfon",
						},
					],
					id: "1vtcxp",
				},
				{
					name: "Salary Module",
					icon: "mdi-alpha-s-circle-outline",
					link: "/",
					color: "light-green",
					show: false,
					locked: true,
					children: [
						{
							name: "Transaction",
							link: "/transaction",
							color: "light-green",
							id: "4q3afb",
							icon: "mdi-account-clock",
						},
						{
							name: "Transaction Record",
							link: "/transaction_record",
							color: "light-green",
							id: "4q3tfb",
							icon: "mdi-account-clock",
						},
						{
							name: "Salary Generate",
							link: "/salary_generate",
							color: "light-green",
							id: "4q3acb",
							icon: "mdi-account-clock",
						},
						{
							name: "Salary Report",
							link: "/salary_report",
							color: "light-green",
							id: "4q3acf",
							icon: "mdi-account-clock",
						},
						{
							name: "Bank Sheet",
							link: "/bank_sheet",
							color: "light-green",
							id: "4q3acf1",
							icon: "mdi-account-clock",
						},
						{
							name: "Pay Slip",
							link: "/pay_slip",
							color: "light-green",
							id: "483acf",
							icon: "mdi-account-clock",
						},
					],
					id: "nfky6b",
				},
				{
					name: "Bonus Module",
					icon: "mdi-alpha-b-circle-outline",
					link: "/",
					color: "yellow",
					show: false,
					locked: true,
					children: [
						{
							name: "Bonus Generate",
							link: "/bonus_generate",
							color: "yellow",
							id: "4q5acb",
							icon: "mdi-account-clock",
						},
						{
							name: "Bonus Report",
							link: "/bonus_report",
							color: "yellow",
							id: "4a3acf",
							icon: "mdi-account-clock",
						},
					],
					id: "n9ky6b",
				},
				{
					name: "PF Module",
					icon: "mdi-alpha-p-circle-outline",
					link: "/",
					color: "yellow",
					show: false,
					locked: true,
					children: [
						{
							name: "PF Transaction",
							link: "/pf_transaction",
							color: "yellow",
							id: "4q8acb",
							icon: "mdi-account-clock",
						},
						{
							name: "PF Transaction Record",
							link: "/pf_transaction_record",
							color: "yellow",
							id: "4a8acf",
							icon: "mdi-account-clock",
						},
						{
							name: "PF Balance Report",
							link: "/pf_balance_report",
							color: "yellow",
							id: "4a8wcf",
							icon: "mdi-account-clock",
						},
						{
							name: "PF Ledger",
							link: "/pf_ledger",
							color: "yellow",
							id: "4a0wcf",
							icon: "mdi-account-clock",
						},
					],
					id: "n9ay6b",
				},
				{
					name: "Report Module",
					icon: "mdi-book-open-variant",
					link: "/",
					color: "deep-orange",
					show: false,
					locked: true,
					children: [
						{
							name: "Salary Report",
							link: "/salary_report",
							color: "deep-orange",
							id: "4s5acb",
							icon: "mdi-account-clock",
						},
						{
							name: "Employee List",
							link: "/employees",
							color: "deep-orange",
							id: "4ea3af",
							icon: "mdi-account-clock",
						},
						{
							name: "Loan List",
							link: "/loans",
							color: "deep-orange",
							id: "l03acf",
							icon: "mdi-account-clock",
						},
						{
							name: "Leave Record",
							link: "/leaves",
							color: "deep-orange",
							id: "l13acf",
							icon: "mdi-account-clock",
						},
					],
					id: "n9k56b",
				},
				{
					name: "Administration Module",
					icon: "mdi-account-tie",
					link: "/",
					color: "cyan",
					show: false,
					locked: true,
					children: [
						{
							name: "Company Profile",
							link: "/company_profile",
							color: "cyan",
							id: "wfmn5",
							icon: "mdi-account-tie",
						},
						{
							name: "Department Entry",
							link: "/department",
							color: "cyan",
							id: "lnywdq",
							icon: "mdi-account-tie",
						},
						{
							name: "Designation Entry",
							link: "/designation",
							color: "cyan",
							id: "cttfch",
							icon: "mdi-account-tie",
						},
						{
							name: "Month Entry",
							link: "/month",
							color: "cyan",
							id: "ctxtch",
							icon: "mdi-account-tie",
						},
						{
							name: "Shift Entry",
							link: "/shift",
							color: "cyan",
							id: "ct82ch",
							icon: "mdi-account-tie",
						},
						{
							name: "Shift Management",
							link: "/shift_management",
							color: "blue",
							id: "i7fpmg",
							icon: "mdi-account-clock",
						},
						{
							name: "Holiday Management",
							link: "/holiday_management",
							color: "cyan",
							id: "chm8ch",
							icon: "mdi-account-tie",
						},
						{
							name: "Area Entry",
							link: "/area",
							color: "cyan",
							id: "574psfk",
							icon: "mdi-account-tie",
						},
						{
							name: "Country Entry",
							link: "/country",
							color: "cyan",
							id: "574pfk",
							icon: "mdi-account-tie",
						},
						{
							name: "Bank Entry",
							link: "/bank",
							color: "cyan",
							id: "574psk",
							icon: "mdi-account-tie",
						},
						{
							name: "Increment Entry",
							link: "/increment",
							color: "blue",
							id: "i3c1is",
							icon: "mdi-account-clock",
						},
						{
							name: "Promotion Entry",
							link: "/promotion",
							color: "blue",
							id: "2ufl4",
							icon: "mdi-account-clock",
						},
						{
							name: "Notice Management",
							link: "/notice",
							color: "blue",
							id: "2ufl5",
							icon: "mdi-account-clock",
						},
						{
							name: "User Management",
							link: "/user_management",
							color: "red",
							id: "9vtlb",
							icon: "mdi-account-clock",
						},
					],
					id: "6cegai",
				},
				{
					name: "Logout",
					icon: "mdi-logout",
					color: "amber",
					show: false,
					locked: true,
					click: true,
					method: 'logout',
					id: 'lxectd'
				}
			],
		};
	},

	watch: {
		$route(to, from) {
			this.menuItems.forEach((menu) => (menu.show = false));
			this.openPages.forEach((page) => (page.active = false));
			let currentPage = this.openPages.find(
				(page) => page.name == to.name
			);
			if (currentPage == undefined && !to.meta.no_tab) {
				this.openPages.push({
					name: to.name,
					link: to.path,
					active: true,
					color: to.meta.color,
				});

				let openPages = JSON.stringify(this.openPages);
				sessionStorage.setItem("openPages", openPages);

			} else if (!to.meta.no_tab) {
				currentPage.active = true;
			}
		},
	},

	async created() {
		this.linkup_api_content = await this.$store.dispatch('company/getLinkupApiContent');

		setInterval(() => (this.time = new Date()), 1000);

		this.checkAccess();

		if (localStorage.getItem("shortcutMenus") != null)
			this.shortcutMenus = JSON.parse(
				localStorage.getItem("shortcutMenus")
			);

		if (sessionStorage.getItem("openPages") != null)
			this.openPages = JSON.parse(
				sessionStorage.getItem("openPages")
			);

		this.openPages.forEach((page) => (page.active = false));

	},

	methods: {
		checkAccess() {
			let role = this.$store.state['user'].role;
			if (role != 'Admin') {
				let permissions = this.$store.state['user'].permissions;
				this.menuItems = this.menuItems.filter((item) => {
					if (item.children) {
						let children = item.children.filter((child) => {

							if (child.children) {
								let sub_children = child.children.filter((sub_child) => permissions.includes(sub_child.name));
								if (sub_children.length > 0) {
									child.children = sub_children;
									return child;
								}
							} else if (permissions.includes(child.name)) {
								return child;
							}

						})

						if (children.length > 0) {
							item.children = children;
							return item
						}
					} else if (item.access) {
						if (permissions.includes(item.name)) {
							return item;
						}
					} else {
						return item;
					}
				});
			}
		},
		closeTab(ind) {
			this.openPages.splice(ind, 1);
			this.$router.push(this.openPages[ind - 1].link);
			let openPages = JSON.stringify(this.openPages);
			sessionStorage.setItem("openPages", openPages);
		},
		async changeShortcutMenus() {
			this.shortcutEditMode = !this.shortcutEditMode;
			if (!this.shortcutEditMode) {
				await this.$store.dispatch('user/updateShortcutMenus', this.shortcutMenus);
			}
		},
		shortcutRemove(index) {
			this.shortcutMenus.splice(index, 1);
		},
		handle_function(method) {
			this[method]();
		},
		async logout() {
			let conf = confirm('Are you sure?');
			if (conf == false) {
				return;
			}
			await this.$store.dispatch('user/logout');
			this.$router.push('/login')
		}
	},
};
</script>

<style lang="scss">
.left-menu {
	.v-treeview-node__root {
		min-height: 28px !important;

		.v-treeview-node__content {
			margin-left: 0px !important;

			.v-treeview-node__prepend {
				margin-right: 0px !important;
			}

			.v-treeview-node__label {
				font-size: 12px !important;
				font-weight: normal !important;
			}
		}
	}
}

#clock {
	// background: #0f3854;
	background: radial-gradient(ellipse at center, #0a2e38 40%, #000000 80%);
	background-size: 100%;
	font-family: "Share Tech Mono", monospace;
	color: #ffffff;
	text-align: center;
	color: #daf6ff;
	text-shadow: 0 0 20px rgba(10, 175, 230, 1), 0 0 20px rgba(10, 175, 230, 0);
	padding: 5px 15px;

	.time {
		letter-spacing: 0.05em;
		font-size: 18px;
		padding: 0;
		margin: 0;
	}

	.date {
		padding: 0;
		margin: 0;
		letter-spacing: 0.1em;
		font-size: 14px;
	}
}

.open-pages {
	.v-tab {
		text-transform: none !important;
		font-weight: normal !important;
		letter-spacing: normal !important;
		min-width: 50px !important;
	}

	.v-slide-group__next,
	.v-slide-group__prev {
		flex: 0 1 20px !important;
		min-width: 20px !important;
	}
}

.active-tab {
	color: white !important;
}

.shortcut-close {
	position: absolute;
	right: -5px;
	top: 0px;
	background: #FF1E0E;
	border-radius: 50%;
	z-index: 99999;
	cursor: pointer;
	height: 17px;
	width: 17px;
	text-align: center;

	i {
		color: #ffffff !important;
		font-size: 13px !important;
		top: -4px;
	}

	&:hover {
		background: #424242;
		transition: .3s;
	}
}
</style>
